<template lang="pug">
    .pagination
        v-btn(x-large block color="secondary" @click="$emit('click')")
          v-icon.mr-1 mdi-chevron-down
          | See more listings
          v-icon.ml-1 mdi-chevron-down
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Pagination",
  computed: {
    ...mapGetters(["listingsPerPage"])
  }
};
</script>
